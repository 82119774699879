
import { computed, defineComponent, PropType, reactive, ref, watch } from '@vue/composition-api'
import cloneDeep from 'lodash/cloneDeep'

import CommonAddEditDialog from '@/components/common/CommonAddEditDialog.vue'
import CommonNumberInput from '@/components/common/CommonNumberInput.vue'
import CommonDateInput from '@/components/common/CommonDateInput.vue'

import { useNotify } from '@/store'

import { handleError } from '@/utils/handleError'
import { isRequired } from '@/utils/validation'
import { dateDashNotationToDot, dateDotNotationToDash } from '@/utils/convertDate'

import { useCreateProductIncrement, useUpdateProductIncrement } from '@/api/productIncrement'

import { isOfType } from '@/utils/types/isOfType'
import { DATA_TYPE, FormField, FORM_FIELDS_ENUM } from '@/utils/types/formField'
import { ProductIncrementInput, ProductIncrementOutput } from '@/api/types/productIncrement'

export default defineComponent({
  name: 'AddEditProductIncrementDialog',
  components: {
    CommonAddEditDialog,
    CommonNumberInput,
    CommonDateInput,
  },
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    productIncrementToEdit: {
      type: Object as PropType<ProductIncrementOutput | null>,
      default: null,
    },
  },
  setup: (props, { root, emit }) => {
    const isEditMode = computed(() => Boolean(props.productIncrementToEdit))

    const { addNotification } = useNotify()

    const FORM_FIELDS: FormField[] = [
      {
        value: 'nr',
        fieldType: FORM_FIELDS_ENUM.TEXT,
        dataTyp: DATA_TYPE.NUMBER,
        isRequired: true,
        rules: [
          (value: string) =>
            isRequired(value, root.$t('planning.timePlanning.components.productIncrement.form.nr') as string),
        ],
      },
      {
        value: 'start',
        fieldType: FORM_FIELDS_ENUM.TEXT,
        dataTyp: DATA_TYPE.DATE,
        isRequired: true,
        rules: [
          (value: string) =>
            isRequired(value, root.$t('planning.timePlanning.components.productIncrement.form.start') as string),
        ],
      },
      {
        value: 'end',
        fieldType: FORM_FIELDS_ENUM.TEXT,
        dataTyp: DATA_TYPE.DATE,
        isRequired: true,
        rules: [
          (value: string) =>
            isRequired(value, root.$t('planning.timePlanning.components.productIncrement.form.end') as string),
        ],
      },
    ]

    const form = ref<ProductIncrementInput | ProductIncrementOutput>(
      props.productIncrementToEdit ? cloneDeep(props.productIncrementToEdit) : ({} as ProductIncrementInput)
    )

    const { createProductIncrement, isLoading: isLoadingCreateProductIncrement } = useCreateProductIncrement()

    async function onAdd(): Promise<void> {
      if (!isOfType<ProductIncrementOutput>(form.value, 'id')) {
        const updatedForm: ProductIncrementInput = cloneDeep(form.value)
        updatedForm.start = dateDotNotationToDash(updatedForm.start)
        updatedForm.end = dateDotNotationToDash(updatedForm.end)

        try {
          await createProductIncrement(updatedForm)

          close()

          addNotification({
            text: root.$t('planning.timePlanning.components.productIncrement.create.message') as string,
            type: 'success',
          })
        } catch (error: unknown) {
          handleError(error)
        }
      }
    }

    const { updateProductIncrement, isLoading: isLoadingUpdateProductIncrement } = useUpdateProductIncrement()

    async function onEdit(): Promise<void> {
      if (isOfType<ProductIncrementOutput>(form.value, 'id')) {
        const updatedForm: ProductIncrementOutput = cloneDeep(form.value)
        updatedForm.start = dateDotNotationToDash(updatedForm.start)
        updatedForm.end = dateDotNotationToDash(updatedForm.end)

        try {
          await updateProductIncrement(updatedForm.id, updatedForm)

          close()

          addNotification({
            text: root.$t('planning.timePlanning.components.ProductIncrement.edit.message') as string,
            type: 'success',
          })
        } catch (error: unknown) {
          handleError(error)
        }
      }
    }

    const isLoadingProductIncrement = computed(
      () => isLoadingCreateProductIncrement.value || isLoadingUpdateProductIncrement.value
    )

    watch(
      () => props.productIncrementToEdit,
      () => {
        if (props.productIncrementToEdit) {
          form.value = cloneDeep(props.productIncrementToEdit)
          form.value.start = dateDashNotationToDot(form.value.start)
          form.value.end = dateDashNotationToDot(form.value.end)
        }
      },
      { immediate: true }
    )

    function close(): void {
      emit('added-edited')
      emit('close')
    }

    return reactive({
      constants: {
        FORM_FIELDS_ENUM,
        DATA_TYPE,

        FORM_FIELDS,
      },
      state: {
        isEditMode,

        form,

        isLoadingProductIncrement,
      },
      listeners: {
        onAdd,

        onEdit,
      },
      functions: {
        formRules: {
          isRequired,
        },
      },
    })
  },
})
